import { any, intersection, is, isEmpty, isNil, omit, pick, values } from 'ramda';
import ObjectID from 'bson-objectid';
import { camelCaseKeys } from 'src/api/camelcase-keys';
import {
  InteractionType,
  InteractionItem,
  ISurveyQuestionSet,
  ActionAttribute,
  CriteriaKey,
  ISurveyLogicQuestion,
  API_VERSION_WITH_NO_RICH_TEXT_SURVEYS,
  API_VERSION_WITH_RICH_TEXT,
  Platform,
  AppInteractionData,
} from 'src/types/core';
import { isType } from 'src/reducers/surveys-multi-apps/surveys-multi-apps.utils';
import { LocalStorage } from 'src/services';
import { sortBy, SortOrder } from 'src/utils';
import {
  getIsSurveyV12WithSkipLogic,
  getIsSurveyV12WithSkipToEnd,
  isDefaultInvoke,
  isNotDefaultInvoke,
} from 'src/interactions/components/survey-multi-apps-form/survey-questions/skip-logic-helpers';
import { getTextFromHtml, getIsStringContainHtml } from 'src/components/molecules/util-handlers';
import {
  DefaultQuestions,
  DefaultQuestionAnswerChoice,
  DefaultQuestionOtherChoice,
  DEFAULT_QUESTION_SET_DATA,
} from './surveys-multi-apps.state';
import {
  QuestionAnswerChoice,
  SurveyQuestionType,
  SurveyRenderAs,
  MultiAppsSurvey,
  SurveyResponseStat,
  AppStats,
  SurveyStatsData,
  SurveyTextAnswers,
} from './surveys-multi-apps.types';
import { templates, FanSignalTemplateName, TemplateName, Templates } from './survey-multi-apps-templates';

export { TemplateName };

const invalidSchemes = ['javascript'];

const DEFAULT_VIEW_PERIOD = 86400;

export const defaultMultiAppsModel: MultiAppsSurvey = {
  id: '',
  orgId: '',
  type: InteractionType.Survey,
  createdBy: '',
  interactionData: [
    {
      interactionId: 'int-id',
      appId: '',
      platform: Platform.iOS,
      active: false,
      codePoints: [],
      criteria: {},
      triggeredBy: [],
      responseCount: 0,
      responseRate: 0,
      responseRateCachedTime: '',
      multipleResponses: false,
      viewCount: 0,
    },
  ],
  name: '',
  description: '',
  questionSetAttributes: [],
  showSuccessMessage: true,
  showTermsAndConditions: false,
  termsAndConditionsLabel: 'Terms & Conditions',
  showDisclaimer: false,
  disclaimerText: '',
  renderAs: SurveyRenderAs.LIST,
  successMessage: 'Thank you!',
  submitButtonText: 'Submit',
  nextButtonText: 'Next',
  viewPeriod: DEFAULT_VIEW_PERIOD,
};

const getTemplates = (): Partial<Templates> => templates;

type GetFlagFn<T extends { _destroy?: number }> = (item: T) => boolean;
type GetIsSelectedSurveyWithSLFn<T extends { id: string }> = (surveys: T[], itemId: string) => boolean;
const isActiveEntity: GetFlagFn<ISurveyQuestionSet | QuestionAnswerChoice | ActionAttribute> = (el) =>
  el._destroy !== 1;
const isNotActiveEntity: GetFlagFn<ISurveyQuestionSet | QuestionAnswerChoice | ActionAttribute> = (el) =>
  el._destroy === 1;

const getIsSelectedSurveyWithSL: GetIsSelectedSurveyWithSLFn<MultiAppsSurvey> = (surveys, item) => {
  const selectedItem = surveys.find((s) => s.id === item);
  return selectedItem ? getIsSurveyV12WithSkipLogic(selectedItem as MultiAppsSurvey) : false;
};

export class SurveyMultiAppsModel {
  static Defaults = defaultMultiAppsModel;
  static DefaultQuestions = DefaultQuestions;
  static DefaultAnswerChoice = DefaultQuestionAnswerChoice;
  static DefaultOtherChoiceData = DefaultQuestionOtherChoice;
  static getTemplates = getTemplates;
  static convertQuestionSetsToLegacy = convertQuestionSetsToLegacy;
  static toLegacy = toLegacy;
  static oldSurveyToNew = oldSurveyToNew;
  static enrichQSetsWithDefaultSkipLogic = enrichQSetsWithDefaultSkipLogic;
  static updQSetsWithDefaultSkipLogic = updQSetsWithDefaultSkipLogic;
  static getIsSelectedSurveyWithSL = getIsSelectedSurveyWithSL;
  static getStatsByMultipleApps = getStatsByMultipleApps;
  static getTextAnswersByMultipleApps = getTextAnswersByMultipleApps;

  static isActiveEntity = isActiveEntity;
  static isNotActiveEntity = isNotActiveEntity;
  static isSelectOtherType = isSelectOtherType;
  static filterOtherType = filterOtherType;
  static sortQuestionChoices = sortQuestionChoices;
  static isQuestionWithOtherChoice = isQuestionWithOtherChoice;

  static getIsContainHtmlFields(model: MultiAppsSurvey): boolean {
    const hasHtmlInQuestionSets = model.questionSetAttributes.some((qSet) => {
      const hasHtmlInQuestion = qSet.questions.some((q) => getIsStringContainHtml(q.value));
      const hasHtmlInAnswerChoices = qSet.questions.some(
        (q) => q.answerChoices && q.answerChoices.some((ans) => getIsStringContainHtml(ans.value)),
      );
      return hasHtmlInQuestion || hasHtmlInAnswerChoices;
    });
    return (
      hasHtmlInQuestionSets ||
      getIsStringContainHtml(model.description) ||
      getIsStringContainHtml(model.disclaimerText) ||
      getIsStringContainHtml(model.successMessage)
    );
  }

  static getParsedHtmlFieldsBeforeSave(model: MultiAppsSurvey): MultiAppsSurvey {
    return {
      ...model,
      description: getIsStringContainHtml(model.description)
        ? (model.description as string)
        : getTextFromHtml(model.description as string | undefined),
      disclaimerText: getIsStringContainHtml(model.disclaimerText)
        ? (model.disclaimerText as string)
        : getTextFromHtml(model.disclaimerText),
      successMessage: getIsStringContainHtml(model.successMessage)
        ? (model.successMessage as string)
        : getTextFromHtml(model.successMessage),
      questionSetAttributes: model.questionSetAttributes.map((qSet) => ({
        ...qSet,
        questions: qSet.questions.map((q) => ({
          ...q,
          value: getIsStringContainHtml(q.value) ? q.value : (getTextFromHtml(q.value) as string),
          answer_choices: q.answer_choices
            ? q.answer_choices.map((ans) => ({
                ...ans,
                value: getIsStringContainHtml(ans.value) ? ans.value : (getTextFromHtml(ans.value) as string),
              }))
            : q.answer_choices,
        })),
      })),
    };
  }

  static getApiVersion(model: MultiAppsSurvey): string {
    return this.getIsContainHtmlFields(model) ? API_VERSION_WITH_RICH_TEXT : API_VERSION_WITH_NO_RICH_TEXT_SURVEYS;
  }

  static setData(model: MultiAppsSurvey, data: Partial<MultiAppsSurvey>): MultiAppsSurvey {
    if (data.selfTargeting === false) {
      data.interactionData = model.interactionData?.map((interaction) => ({
        ...interaction,
        active: true as boolean,
        codePoints: [],
        criteria: interaction.criteria
          ? omit(
              Object.keys(interaction.criteria).filter((key) => key !== '_version'),
              interaction.criteria,
            )
          : undefined,
      }));
    }

    return { ...model, ...data };
  }

  static addQuestionSet(model: MultiAppsSurvey, type: SurveyQuestionType): MultiAppsSurvey {
    const question = camelCaseKeys({ ...DefaultQuestions[type] }) as ISurveyLogicQuestion;
    const answerChoices = question.answerChoices
      ? question.answerChoices.map((ans) => ({ ...ans, logicalId: ObjectID().toHexString() }))
      : undefined;

    return {
      ...model,
      questionSetAttributes: [
        ...model.questionSetAttributes.filter(isActiveEntity),
        {
          ...DEFAULT_QUESTION_SET_DATA,
          logicalId: ObjectID().toHexString(),
          order: model.questionSetAttributes.filter(isActiveEntity).length,
          questions: [{ ...question, logicalId: ObjectID().toHexString(), answerChoices }],
        },
        ...model.questionSetAttributes.filter(isNotActiveEntity),
      ],
    };
  }

  static updateQuestionSet(model: MultiAppsSurvey, pos: number, data: ISurveyQuestionSet): MultiAppsSurvey {
    const updated = [...model.questionSetAttributes];
    updated.splice(pos, 1, sortQuestionChoices(data));
    const hasSL = getIsSurveyV12WithSkipLogic({ ...model, questionSetAttributes: updated });
    const isShowSuccessRequired = hasSL
      ? getIsSurveyV12WithSkipToEnd({ ...model, questionSetAttributes: updated })
      : false;

    return {
      ...model,
      questionSetAttributes: updated,
      renderAs: hasSL ? SurveyRenderAs.PAGED : SurveyRenderAs.LIST,
      showSuccessMessage: isShowSuccessRequired || model.showSuccessMessage,
    };
  }

  static setQuestionSetPosition(model: MultiAppsSurvey, prevPos: number, newPos: number): MultiAppsSurvey {
    const updated = [...model.questionSetAttributes];
    const firstMovedQuestionSet = updated[prevPos];
    const secondMovedQuestionSet = updated[newPos];
    updated[newPos] = { ...firstMovedQuestionSet, order: newPos };
    updated[prevPos] = { ...secondMovedQuestionSet, order: prevPos };
    return {
      ...model,
      questionSetAttributes: updated,
    };
  }

  static removeQuestionSet(model: MultiAppsSurvey, id: string): MultiAppsSurvey {
    const targetQuestionSet: ISurveyQuestionSet | undefined = model.questionSetAttributes.find(
      (item) => item.logicalId === id,
    );
    if (!targetQuestionSet) {
      return model;
    }

    const updated: ISurveyQuestionSet[] = targetQuestionSet.id
      ? [
          ...model.questionSetAttributes
            .filter((qSet) => qSet.logicalId !== targetQuestionSet.logicalId)
            .map((el, order) => ({ ...el, order })),
          { ...targetQuestionSet, order: model.questionSetAttributes.length - 1, _destroy: 1 },
        ]
      : [
          ...model.questionSetAttributes
            .filter((qSet) => qSet.logicalId !== targetQuestionSet.logicalId)
            .map((el, order) => ({ ...el, order })),
        ];

    const hasSL = getIsSurveyV12WithSkipLogic({ ...model, questionSetAttributes: updated });
    const isShowSuccessRequired = hasSL
      ? getIsSurveyV12WithSkipToEnd({ ...model, questionSetAttributes: updated })
      : false;

    return {
      ...model,
      questionSetAttributes: updated,
      renderAs: hasSL ? SurveyRenderAs.PAGED : SurveyRenderAs.LIST,
      showSuccessMessage: isShowSuccessRequired || model.showSuccessMessage,
    };
  }

  static parseSurveyBeforeSave(model: MultiAppsSurvey, forceActive: boolean): MultiAppsSurvey {
    return {
      ...model,
      interactionData:
        model.interactionData.length > 0
          ? model.interactionData.map((interaction) => ({
              ...interaction,
              active: interaction.appId === model.id ? forceActive : interaction.active,
            }))
          : model.interactionData,
      description: model.description === '' ? null : model.description,
      questionSetAttributes: model.questionSetAttributes.map(handleQSetOtherAnsChoices),
    };
  }

  static serialize(model: MultiAppsSurvey): MultiAppsSurvey {
    return model;
  }

  static getTemplate(name: TemplateName) {
    let item = getTemplates()[name];
    if (name === FanSignalTemplateName.LostFan) {
      item = LocalStorage.getValue(name);
    }

    return enrichLegacyTemplate(item);
  }

  static isTemplateExist(name: TemplateName | string) {
    return Object.keys(getTemplates()).includes(name) || !!LocalStorage.getValue(name);
  }

  static isCommunitySurvey(name: MultiAppsSurvey['fromTemplate']) {
    return name ? ['covid_19_customer_survey'].includes(name) : false;
  }

  static isValid(model: MultiAppsSurvey): boolean {
    const questions = model.questionSetAttributes.filter(isActiveEntity);
    const requiredProps = pick(['name', 'title'], model);
    return !any(isEmpty)(values({ ...requiredProps, questions }));
  }

  /**
   * Ensure that the url parameter is valid.
   * @param {MultiAppsSurvey} model The survey model
   * @returns True if the url is empty or begins with 'http'. False otherwise.
   */
  static isValidUrl(url: string): boolean {
    const isUrl = !!url;
    if (!isUrl) {
      return true;
    }
    const scheme = url.split(':')[0];
    return invalidSchemes.indexOf(scheme.toLowerCase()) < 0;
  }

  static isValidQuestionSets(model: MultiAppsSurvey): boolean {
    const questions = model.questionSetAttributes.filter(isActiveEntity).map((q) => q.questions[0]);
    const validity = questions.map((q) => {
      if (isType.Singleline(q) || isType.Nps(q)) {
        return withRequiredProps(['value'], q);
      }
      if (isType.Multichoice(q) || isType.Multiselect(q)) {
        const answerValues = (q.answerChoices || []).find((a) => !a.value);
        return withRequiredProps(['value', 'otherChoiceText'], q) && !answerValues;
      }
      if (isType.Range(q)) {
        return withRequiredProps(['value', 'min', 'maxLabel', 'minLabel'], q);
      }
      return withRequiredProps(['value'], q);
    });

    return validity.find((valid) => !valid) === undefined;

    function withRequiredProps(props: (keyof ISurveyLogicQuestion)[], q: ISurveyLogicQuestion): boolean {
      return !any(isEmpty)(values(pick(props, q)));
    }
  }

  static isValidTargeting(model: MultiAppsSurvey): boolean {
    return is(Boolean, model.selfTargeting);
  }

  static isTriggeredBy(
    { interactionData = defaultMultiAppsModel.interactionData }: MultiAppsSurvey,
    interactionId: string,
  ) {
    return interactionData.some((interaction) =>
      interaction.triggeredBy?.some((trigger) => trigger.id === interactionId),
    );
  }

  static isFromLoveDialog(codePoints?: InteractionItem['code_points']): boolean {
    if (!codePoints) {
      return false;
    }
    // https://github.com/apptentive/pupum/blob/33c8241050c5a097075e4facdb2a669bbec28045/legacy/assets/models/survey.js#L105
    const points = Array.isArray(codePoints) ? codePoints : [codePoints];
    return intersection(loveDialogCodePoints(), points).length > 0;
  }

  static invokeInteractions(triggeredBy: MultiAppsSurvey['interactionData'][0]['triggeredBy']) {
    return {
      loveDialogs: triggeredBy ? triggeredBy.filter((item) => item.type === InteractionType.EnjoymentDialog) : [],
      notes: triggeredBy ? triggeredBy.filter((item) => item.type === InteractionType.TextModal) : [],
    };
  }

  static getIsSelectedSurveyWithRichText(surveys: MultiAppsSurvey[], itemId: string): boolean {
    const selectedItem = surveys.find((s) => s.id === itemId);
    return selectedItem ? SurveyMultiAppsModel.getIsContainHtmlFields(selectedItem as MultiAppsSurvey) : false;
  }
}

function isSelectOtherType(el: QuestionAnswerChoice) {
  return el.type && el.type === 'select_other';
}

function filterOtherType(el: QuestionAnswerChoice) {
  return el.type && el.type !== 'select_other';
}

function sortQuestionChoices(data: ISurveyQuestionSet): ISurveyQuestionSet {
  const question = data.questions[0];
  if (!question.answerChoices || isEmpty(question.answerChoices)) {
    return data;
  }

  const hasOtherChoice = question.answerChoices.some(isSelectOtherType);
  if (hasOtherChoice && question.answerChoices.findIndex(isSelectOtherType) < question.answerChoices.length - 1) {
    const otherChoice = question.answerChoices.find(isSelectOtherType) as QuestionAnswerChoice;
    const answerChoices = [...question.answerChoices.filter(filterOtherType), otherChoice];
    return {
      ...data,
      questions: [{ ...question, answerChoices }],
    };
  }

  return data;
}

function handleQSetOtherAnsChoices(qSet: ISurveyQuestionSet): ISurveyQuestionSet {
  const question = qSet.questions[0];
  if (!question.answerChoices || isEmpty(question.answerChoices)) {
    return qSet;
  }

  const otherChoiceOption = question.answerChoices.find(isSelectOtherType) as QuestionAnswerChoice;
  const isActiveOtherChoice = otherChoiceOption && isActiveEntity(otherChoiceOption);

  return {
    ...qSet,
    questions: [
      {
        ...question,
        otherChoiceText: otherChoiceOption && otherChoiceOption.value ? otherChoiceOption.value : 'Other',
        otherChoice: !!isActiveOtherChoice,
      },
    ],
  };
}

function loveDialogCodePoints() {
  return [
    'enjoyment_dialog.cancel',
    'enjoyment_dialog.launch',
    'enjoyment_dialog.no',
    'enjoyment_dialog.yes',
    'feedback_dialog.cancel',
    'feedback_dialog.decline',
    'feedback_dialog.dismiss',
    'feedback_dialog.launch',
    'feedback_dialog.skip_view_messages',
    'feedback_dialog.submit',
    'feedback_dialog.view_messages',
    'rating_dialog.cancel',
    'rating_dialog.decline',
    'rating_dialog.launch',
    'rating_dialog.rate',
    'rating_dialog.remind',
    'rating_dialog.yes',
  ];
}

function oldSurveyToNew(item: any): MultiAppsSurvey {
  // README: See src/api/surveys-multi-apps.ts#L64
  const json = camelCaseKeys(item) as any;
  return {
    ...json,
    viewPeriod: json.interactionData[0]?.viewPeriod || DEFAULT_VIEW_PERIOD,
    questionSetAttributes: sortBy(json.questionSetAttributes.map(sortQuestionChoices), 'order', SortOrder.asc),
    interactionData: json.interactionData.map((data: AppInteractionData) =>
      camelCaseKeys(data),
    ) as AppInteractionData[],
  };
}

function enrichLegacyTemplate(item: any) {
  return {
    toNew: () => (item ? oldSurveyToNew(item) : item),
    toPure: () => item,
  };
}

function convertQuestionSetsToLegacy(questionSets: ISurveyQuestionSet[]) {
  return questionSets.map((qSet) => ({
    id: qSet.id,
    logical_id: qSet.logicalId,
    order: qSet.order,
    actions_attributes: (qSet.invokes || []).map((invoke) => ({
      ...omit(['nextLogicalId'], invoke),
      next_logical_id: invoke.nextLogicalId,
    })),
    questions_attributes: qSet.questions.map((question: ISurveyLogicQuestion) => ({
      ...omit(
        [
          'logicalId',
          'errorMessage',
          'minSelections',
          'maxSelections',
          'otherChoice',
          'otherChoiceText',
          'minLabel',
          'maxLabel',
          'freeformHint',
          'answerChoices',
          'answer_choices',
        ],
        question,
      ),
      logical_id: question.logicalId,
      error_message: question.errorMessage,
      min_selections: question.minSelections,
      max_selections: question.maxSelections,
      other_choice: question.otherChoice,
      other_choice_text: question.otherChoiceText,
      min_label: question.minLabel,
      max_label: question.maxLabel,
      freeform_hint: question.freeformHint,
      instructions: question.instructions,
      _destroy: question._destroy,
      answer_choices_attributes: (question.answerChoices || []).map((answer) => ({
        ...omit(['logicalId'], answer),
        logical_id: answer.logicalId,
      })),
    })),
    _destroy: qSet._destroy,
  }));
}

// README: Temp, all props should be via underscore, and avoid oldSurveyToNew
// TODO: Avoid converting, and clean up codes and forms to native props
function toLegacy(model: MultiAppsSurvey) {
  const unknowProps = {
    display_response_rate: '',
    display_type: model.displayType || null,
    show_terms_and_conditions: model.showTermsAndConditions,
    terms_and_conditions_label: model.termsAndConditionsLabel || null,
    terms_and_conditions_link: model.termsAndConditionsLink || null,
    style_name: model.styleName || null,
    show_disclaimer: model.showDisclaimer,
    disclaimer_text: model.disclaimerText || null,
    intro_button_text: model.introButtonText || '',
  };
  return {
    ...omit(
      [
        'orgId',
        'appId',
        'interactionData',
        'questionSetAttributes',
        'organizationId',
        'introButtonText',
        'createdBy',
        'successButtonText',
        'criteria',
        'codePoints',
        'displayType',
        'fromTemplate',
        'multipleResponses',
        'showSuccessMessage',
        'successMessage',
        'contactUrl',
        'contactUrlText',
        'selfTargeting',
        'questions',
        'templateLocked',
        'createdAt',
        'updatedAt',
        'endTime',
        'startTime',
        'respMax',
        'responseCount',
        'responseRate',
        'responseRateCachedTime',
        'triggeredBy',
        'viewLimit',
        'viewCount',
        'viewPeriod',
        'showTermsAndConditions',
        'termsAndConditionsLabel',
        'termsAndConditionsLink',
        'styleName',
        'renderAs',
        'questionSets',
        'showDisclaimer',
        'disclaimerText',
        'submitButtonText',
        'nextButtonText',
      ],
      model,
    ),
    created_at: model.createdAt,
    updated_at: model.updatedAt,
    show_success_message: model.showSuccessMessage,
    success_message: model.successMessage,
    render_as: model.renderAs,
    contact_url: model.contactUrl || null,
    contact_url_text: model.contactUrlText || null,
    interaction_data: model.interactionData.map((data) => ({
      active: data.active,
      app_id: data.appId,
      multiple_responses: data.multipleResponses,
      criteria: data.criteria,
      code_points: data.codePoints,
      triggered_by: data.triggeredBy,
      view_count: data.viewCount,
      response_count: data.responseCount,
      response_rate: data.responseRate,
      response_rate_cached_time: data.responseRateCachedTime,
    })),
    question_sets_attributes: convertQuestionSetsToLegacy(model.questionSetAttributes),
    from_template: model.fromTemplate || '', // TemplateName
    template_locked: model.templateLocked || false,
    self_targeting: model.selfTargeting,
    resp_max: model.respMax || null,
    end_time: model.endTime || null,
    start_time: model.startTime || null,
    view_limit: model.viewLimit || null,
    view_period: model.viewPeriod || 86400, // days
    submit_button_text: model.submitButtonText,
    next_button_text: model.nextButtonText,
    created_by: model.createdBy,
    success_button_text: model.successButtonText,
    ...unknowProps,
  };
}

function isQuestionWithOtherChoice(question: ISurveyLogicQuestion): boolean {
  if (!question.answer_choices || isEmpty(question.answer_choices)) {
    return false;
  }

  return question.answer_choices.some(isSelectOtherType);
}

const getIsEmptyAnsValue = (value: any) =>
  value === '' || (typeof value === 'number' && value < -1) || isNil(value) || Number.isNaN(value);

const hasEmptyAnswer = (answers: Record<CriteriaKey, any>[]) => {
  let res = false;
  answers.forEach((ans) => {
    const criteria = Object.values(ans)[0];
    const value = Object.values(criteria)[0];

    if (getIsEmptyAnsValue(value)) {
      res = true;
    }
  });

  return res;
};

const isEmptyAnswer = (ans: Record<CriteriaKey, any>) => getIsEmptyAnsValue(Object.values(ans)[0]);

const isCompletedInvoke = (inv: ActionAttribute) => {
  let hasEmptyCriteria = false;
  if (inv.criteria && !isEmpty(Object.values(inv.criteria))) {
    const objAnswrs = Object.values(inv.criteria)[0];
    const isArr = Array.isArray(objAnswrs);

    if (isArr) {
      hasEmptyCriteria = hasEmptyAnswer(objAnswrs);
    } else {
      hasEmptyCriteria = !!(inv.criteria && !isEmpty(objAnswrs) && isEmptyAnswer(objAnswrs));
    }
  }

  const hasCriteriaAndNoNextLogID = !!(inv.behavior === 'continue' && isNotDefaultInvoke(inv) && !inv.nextLogicalId);
  const hasNextLogIDAndNoCriteria = !!(inv.behavior === 'continue' && isDefaultInvoke(inv) && inv.nextLogicalId);
  const hasEndOfSurveyAndEmptyCriteria = !!(inv.behavior === 'end' && isDefaultInvoke(inv) && !inv.nextLogicalId);

  return !(
    hasNextLogIDAndNoCriteria ||
    hasCriteriaAndNoNextLogID ||
    hasEmptyCriteria ||
    hasEndOfSurveyAndEmptyCriteria
  );
};

function enrichQSetsWithDefaultSkipLogic(questionSets: ISurveyQuestionSet[]): ISurveyQuestionSet[] {
  return questionSets.map((qSet, index) => {
    // no modification for the last question set in survey-v12
    if (questionSets.length === index + 1) {
      return {
        ...qSet,
        invokes: [{ behavior: 'end', order: 0 }],
      };
    }

    const nextLogicalId = questionSets[index + 1].logicalId || '';
    // check if question set is already contains skip logic, so default skip logic rules will be added as well
    if (qSet.invokes) {
      const filteredInvokes = qSet.invokes.filter(isCompletedInvoke);
      return {
        ...qSet,
        invokes: [
          ...filteredInvokes,
          {
            behavior: 'continue',
            nextLogicalId,
            criteria: {},
            order: filteredInvokes.length,
          },
        ],
      };
    }

    // there is no skip logic in question set, so there will be only default skip logic rules
    return {
      ...qSet,
      invokes: [
        {
          behavior: 'continue',
          nextLogicalId,
          criteria: {},
          order: 0,
        },
      ],
    };
  });
}

function updQSetsWithDefaultSkipLogic(questionSets: ISurveyQuestionSet[]): ISurveyQuestionSet[] {
  const activeQSets = [...questionSets.filter(SurveyMultiAppsModel.isActiveEntity)];
  return questionSets.map((qSet, index) => {
    // return if question removed
    if (!SurveyMultiAppsModel.isActiveEntity(qSet)) {
      return qSet;
    }

    const invokes: ActionAttribute[] = qSet.invokes && !isEmpty(qSet.invokes) ? qSet.invokes : [];
    const defaultInvoke = invokes.find(isDefaultInvoke) as ActionAttribute;

    // modification for the last question set in survey-v12
    if (activeQSets.length === index + 1) {
      return {
        ...qSet,
        invokes: [
          ...invokes.filter(isNotDefaultInvoke),
          { ...defaultInvoke, behavior: 'end', nextLogicalId: '', criteria: {}, order: 0 },
        ],
      };
    }

    // question set is already contains skip logic, so default skip logic rules will be changed
    const nextQSetId = questionSets[index + 1].logicalId || '';
    let notDefaultInvokes: ActionAttribute[] = [];
    invokes.forEach((inv) => {
      if (isDefaultInvoke(inv)) {
        return;
      }

      if (!isCompletedInvoke(inv)) {
        notDefaultInvokes = inv.id ? [...notDefaultInvokes, { ...inv, _destroy: 1 }] : notDefaultInvokes;
      } else {
        notDefaultInvokes = [...notDefaultInvokes, inv];
      }
    });

    return {
      ...qSet,
      invokes: [
        ...notDefaultInvokes,
        {
          ...defaultInvoke,
          behavior: 'continue',
          nextLogicalId: nextQSetId,
          criteria: {},
          order: notDefaultInvokes.length,
        },
      ],
    };
  });
}

type QuestionAnswer = { choice: string; count: number };

function getStatsByMultipleApps(stats: AppStats, apps: string[]) {
  let statsByMultipleApps: SurveyResponseStat[] = [];

  const mergeAnswerCounts = (existingAnsw: QuestionAnswer[], newAnsw: QuestionAnswer[]) => {
    const newAnswMap = new Map(newAnsw.map((answ) => [answ.choice, answ.count]));
    return existingAnsw.map((answ) => {
      const newCount = newAnswMap.get(answ.choice) || 0;
      return { ...answ, count: answ.count + newCount };
    });
  };

  const selectedAppsMap = new Map(apps.map((app) => [app, true]));
  const questionsStatsMap = new Map<string, SurveyResponseStat>();

  Object.keys(stats).forEach((appKey) => {
    if (!selectedAppsMap.has(appKey)) {
      return;
    }

    const appStatsData: SurveyStatsData = stats[appKey];
    const newAppStats = appStatsData.stats || [];

    newAppStats.forEach((stat) => {
      if (questionsStatsMap.has(stat.id)) {
        const existingStats = questionsStatsMap.get(stat.id) as SurveyResponseStat;
        const updatedStat = { ...existingStats, answers: mergeAnswerCounts(existingStats.answers, stat.answers) };
        statsByMultipleApps = [...statsByMultipleApps.filter((s) => s.id !== stat.id), updatedStat];
        return;
      }

      questionsStatsMap.set(stat.id, stat);
      statsByMultipleApps = [...statsByMultipleApps, stat];
    });
  });

  return statsByMultipleApps;
}

function getTextAnswersByMultipleApps(stats: AppStats, apps: string[]) {
  const textAnswersByMultipleApps: Record<string, SurveyTextAnswers> = {};

  const selectedAppsMap = new Map(apps.map((app) => [app, true]));

  Object.keys(stats).forEach((appKey) => {
    if (!selectedAppsMap.has(appKey)) {
      return;
    }

    const appStatsData: SurveyStatsData = stats[appKey];
    const newAppTextAnswers = appStatsData.textAnswers || {};

    Object.entries(newAppTextAnswers).forEach(([questionId, textAnswer]) => {
      const existingQuestionAnswers = textAnswersByMultipleApps[questionId]?.answers || [];
      textAnswersByMultipleApps[questionId] = {
        ...textAnswer,
        answers: [...existingQuestionAnswers, ...textAnswer.answers],
      };
    });
  });

  return textAnswersByMultipleApps;
}
